import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import imageSrc from "./../lib/image-src"
import styles from "../styles/news.module.css"
import TopHeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import RelatedNews from "../components/newsPage-components/news/news"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent"
import PicDivider from "../components/themenseite-components/picture-divider/picturre-divider"

export default ({ data, location }) => {
  const cookieConsentRef = React.createRef()

  const news = data.datoCmsNews
  const [play, setPlay] = useState(false)
  const vidRef = useRef(null)
  let categoryIconClass = ""
  function clickHnadler() {
    setPlay(true)
    vidRef.current.play()
    vidRef.current.setAttribute("controls", "controls")
  }
  function pauseHandler() {
    setPlay(false)
    vidRef.current.pause()
    vidRef.current.removeAttribute("controls")
  }

  switch (data.datoCmsNews.category) {
    case "Shopware":
      categoryIconClass = styles.shopwareIcon
      break
    case "Apps":
      categoryIconClass = styles.crossPlatformIcon
      break
    case "Digitalwert":
      categoryIconClass = styles.digitalwertIcon
      break
    default:
      categoryIconClass = styles.digitalwertIcon
      break
  }

  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <TopHeaderMenu url={location.href} page="news" />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>
          {data.datoCmsNews.seo
            ? data.datoCmsNews.seo.title
            : "digitalwert | News"}
        </title>
      </Helmet>
      <header className={styles.headerContainer}>
        <div className={styles.categoryMainPart}>
          <div className={styles.categorySideBar}>
            <div className={styles.author}>
              <div className={styles.authorNameAndDate}>
                <p>
                  {data.datoCmsNews.provider ? data.datoCmsNews.provider : ""}
                </p>
                <time>
                  {data.datoCmsNews.providerPublicationDate
                    ? data.datoCmsNews.providerPublicationDate
                    : {}}
                </time>
              </div>
              <div className={categoryIconClass}>
                <div></div>
              </div>
            </div>
          </div>
          <div className={styles.h1AndPContainer}>
            <div className={`${styles.author} ${styles.mobileAuthor}`}>
              <div className={styles.authorNameAndDate}>
                <p>
                  {data.datoCmsNews.provider ? data.datoCmsNews.provider : ""}
                </p>
                <time>
                  {data.datoCmsNews.providerPublicationDate
                    ? data.datoCmsNews.providerPublicationDate
                    : {}}
                </time>
              </div>
              <div className={categoryIconClass}>
                <div></div>
              </div>
            </div>
            <h1 className={styles.h1}>{data.datoCmsNews.title}</h1>
          </div>
          <div className={styles.categorySideBar} />
        </div>
      </header>
      <div className={styles.headerImageContainer}>
        <div className={styles.imageAndSentenceContainer}>
          {data.datoCmsNews.teaserimage.isImage ? (
            <div
              className={styles.imageContainer}
              style={
                data.datoCmsNews.teaserimage
                  ? {
                      backgroundImage: `url(${imageSrc(
                        data.datoCmsNews.teaserimage
                      )})`,
                    }
                  : {}
              }
            ></div>
          ) : (
            <video
              controls={true}
              poster={data.datoCmsNews.teaserimage.thumbnailUrl}
              data-setup="{}"
              loop={true}
              className={styles.videoBanner}
            >
              <source
                src={data.datoCmsNews.teaserimage.video.mp4Url}
                type="video/mp4"
              />
            </video>
          )}
          <div className={styles.verticalSentence}>
            {data.datoCmsNews.teaserimagetitle && (
              <p
                className={styles.teaserImageTitle}
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsNews.teaserimagetitle,
                }}
              ></p>
            )}
          </div>
        </div>
        <div className={styles.teaserT}>

        <div
              dangerouslySetInnerHTML={{
                __html: data.datoCmsNews.teasertext,
              }}
              ></div>
              </div>
      </div>
      <div className={styles.mainDiv}>
        {news.content.map((contentElement, i) => {
          if (!contentElement.id) {
            return <div key={i}>unknown content element</div>
          }

          switch (contentElement.internal.type) {
            case "DatoCmsSimpletext":
              return (
                <div key={i} className={styles.contentContainer}>
                  <div className={styles.headlineContainer}>
                    {data.datoCmsNews.content[i].number && (
                      <p className={styles.headlineNumber}>
                        {" "}
                        {data.datoCmsNews.content[i].number}
                      </p>
                    )}
                    {data.datoCmsNews.content[i].headline && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.datoCmsNews.content[i].headline,
                        }}
                      />
                    )}
                  </div>
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: data.datoCmsNews.content[i].text,
                    }}
                  ></div>
                </div>
              )

            case "DatoCmsMultyimageswithtitle":
              if (news.content[i].images.length === 2) {
                return (
                  <div key={i} className={styles.bodyImgContainer}>
                    <div className={styles.bodyImg1}>
                      <div
                        className={contentElement.shadow ? "shadow" : ""}
                        style={{
                          backgroundImage: `url(${
                            news.content[i].images[0] !== null
                              ? imageSrc(news.content[i].images[0])
                              : null
                          })`,
                        }}
                      ></div>
                      <p>{news.content[i].images[0].title}</p>
                    </div>
                    <div className={styles.bodyImg2}>
                      <div
                        className={contentElement.shadow ? "shadow" : ""}
                        style={{
                          backgroundImage: `url(${
                            news.content[i].images[1] !== null
                              ? imageSrc(news.content[i].images[1])
                              : null
                          }})`,
                        }}
                      ></div>
                      <p>{news.content[i].images[1].title}</p>
                    </div>
                  </div>
                )
              }
            /* falls through */
            case "DatoCmsVideofullwidth":
              return (
                <div key={i} className={styles.bodyVideo}>
                  {/* <video title="video"
                  ref={vidRef}
                  onClick={pauseHandler}
                  poster={imageSrc(news.content[i].vorschaubild)}
                    style={{
                      background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imageSrc(news.content[i].vorschaubild)}) no-repeat center center fixed`,
                      backgroundSize: "contain",
                    }}
                  >
                    <track default kind="captions" src={data.datoCmsNews.content[i].videomp4.video.mp4Url} srclang="en" label="English" />
                    <source src={data.datoCmsNews.content[i].videomp4.video.mp4Url} type="video/mp4" />
                  </video>
                  <div className={styles.playButtonContainer} style={(play) ? {display: 'none'} : {display: 'block'}}>
                    <div className={styles.playButton}>
                      <div role="button" aria-label="video" className={styles.playButtonIcon} onClick={clickHnadler} onKeyUp={clickHnadler}  tabIndex={0}></div>
                    </div>
                    <p>VIDEO ABSPIELEN</p>
                  </div> */}
                  <video
                    controls={true}
                    poster={imageSrc(news.content[i].vorschaubild)}
                    data-setup="{}"
                    loop={true}
                    className={styles.videoBanner}
                  >
                    <source
                      src={data.datoCmsNews.content[i].videomp4.video.mp4Url}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )
            case "DatoCmsImageBanner":
              return (
                <div
                  key={contentElement.id}
                  className={styles.imageBanner}
                  style={{
                    backgroundImage: contentElement.backgroundImage
                      ? "url(" + contentElement.backgroundImage.url + ")"
                      : "linear-gradient( " +
                        contentElement.backgroundColorFadeOne.hex +
                        ", " +
                        contentElement.backgroundColorFadeTwo.hex +
                        ")",
                  }}
                >
                  <img src={imageSrc(contentElement.bannerImage)} />
                </div>
              )
            case "DatoCmsPictureDivider":
              return (
                <PicDivider
                  bgGradientColorLeft={contentElement.bgGradientColorLeft}
                  bgGradientColorRight={contentElement.bgGradientColorRight}
                  img={imageSrc(contentElement.image)}
                  imgalt={contentElement.image?.alt}
                  key={i}
                />
              )
            default:
              return <div key={i}>unknown content element type</div>
          }
        })}
      </div>
      <RelatedNews items={data.allDatoCmsNews.nodes} />
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export const query = graphql`
  query($id: String!, $category: String!) {
    allDatoCmsNews(
      limit: 5
      filter: { category: { eq: $category }, id: { ne: $id } }
      sort: { fields: providerPublicationDate, order: DESC }
    ) {
      nodes {
        id
        category
        providerPublicationDate
        title
        slug
        teasertext
        teaserimage {
          alt
          fluid(imgixParams: { auto: "compress" }) {
            src
          }
          url
          isImage
        }
        teaserimagetitle
      }
    }
    datoCmsNews(id: { eq: $id }) {
      seo {
        description
        title
      }
      id
      category
      provider
      providerPublicationDate(formatString: "DD.MM.YYYY")
      title
      slug
      teasertext
      teaserimage {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
        isImage
        video {
          thumbnailUrl
          mp4Url
        }
      }
      teaserimagetitle
      content {
        ... on DatoCmsPictureDivider {
          id
          bgGradientColorLeft
          bgGradientColorRight
          image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsMultyimageswithtitle {
          id
          shadow
          images {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            title
          }
          internal {
            type
          }
        }
        ... on DatoCmsVideofullwidth {
          id
          videomp4 {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            video {
              mp4Url
            }
          }
          vorschaubild {
            fluid {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsSimpletext {
          number
          text
          headline
          internal {
            type
          }
          id
        }
        ... on DatoCmsImageBanner {
          id
          internal {
            type
          }
          bannerImage: image {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          backgroundImage {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          backgroundColorFadeOne {
            hex
          }
          backgroundColorFadeTwo {
            hex
          }
        }
      }
    }
  }
`
