import React from "react"
import styles from "./component.module.css"
import { Link } from "gatsby"

import ShopwareImg from "../../../images/img-news-shopware.png"

const News = props => {
  return (
    <div className={styles.newsContainer}>
      <a href="/news" className={styles.a}>
        Weitere News
      </a>
      <div className={styles.allPartsContainer}>
        {props.items.sort((a, b) => {
          return (
            new Date(b.providerPublicationDate) -
            new Date(a.providerPublicationDate)
          )
        }).map(newsArticle => {
          let categoryClass = ""
          let categoryDescriptionClass = ""
          categoryClass = styles.shopware
          categoryDescriptionClass = styles.shopwareDescription
          let categoryIconClass = ""
          switch (newsArticle.category) {
            case "Shopware":
              categoryIconClass = styles.shopwareIcon
              break
            case "Apps":
              categoryIconClass = styles.crossPlatformIcon
              break
            case "Digitalwert":
              categoryIconClass = styles.digitalwertIcon
              break
            default:
              categoryClass = styles.shopware
              break
          }

          return (
            <Link to={"/news/" + newsArticle.slug + "/"}>
              <div key={newsArticle.id} className={categoryClass}>
                <div className={categoryDescriptionClass}>
                  <a>
                    <h2 className={styles.h2}>
                      {newsArticle.title}
                      <span
                        aria-label="more info"
                        className={styles.more}
                      ></span>
                    </h2>
                  </a>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newsArticle.teasertext,
                    }}
                  ></p>
                  <div className={categoryIconClass}>
                    <div></div>
                  </div>
                </div>
                {newsArticle.teaserimage && (
                  <img
                    className={styles.newsImg}
                    src={
                      newsArticle.teaserimage
                        ? newsArticle.teaserimage?.fluid?.src
                        : { ShopwareImg }
                    }
                    alt={newsArticle.teaserimage.alt}
                  />
                )}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default News
